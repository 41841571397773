import React from 'react'
import { Breadcrumbs, withStyles } from '@material-ui/core'

const CssBreadcrumbs = withStyles({
    root: {
      '& .MuiTypography-body1': {
        fontFamily: 'Open Sans',
        fontSize: '14px'
      },
      '& .MuiBreadcrumbs-root': {
        fontFamily: 'Open Sans',
        fontSize: '14px'
      },
      '& .MuiBreadcrumbs-ol': {
        fontFamily: 'Open Sans',
        fontSize: '14px'
      },
      '& .MuiBreadcrumbs-li': {
        fontFamily: 'Open Sans',
        fontSize: '14px'
      },
      '& .MuiBreadcrumbs-separator': {
        fontFamily: 'Open Sans',
        fontSize: '14px'
      },
    },
  })(Breadcrumbs)

const CustomBreadcrumbs = props => (
    <CssBreadcrumbs aria-label="breadcrumb">
        {props.children}
    </CssBreadcrumbs>
)

export default CustomBreadcrumbs
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Button, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
    WhatsApp: {
        backgroundColor: '#25D366',
        fontFamily: 'Open Sans',
        fontSize: '14px',
        '&:hover': {
            backgroundColor: '#25D366'
        },
    }
})

const ButtonWhatsApp = props => {

    const data = useStaticQuery(graphql`
      {
        WHATS_APP_NUMBER: setting(environmentName: {eq: "WHATS_APP_NUMBER"}) {
          value
        }
      }
    `)
    const WHATS_APP_NUMBER = data.WHATS_APP_NUMBER.value

    const classes = useStyles()

    const click = (action, param, attribute) => {
        if (action === "redirect") {
            window.open(`https://api.whatsapp.com/send?phone=55${WHATS_APP_NUMBER}&text=Ol%C3%A1!%20Gostaria%20de%20saber%20mais%20sobre%20o%20item%20https%3A%2F%2F${process.env.DOMAIN_URL}%2F${attribute}%2F${param}`, '_blank');
        }
    }

    return (
        <Button className={classes.WhatsApp} size={props.size || "medium"} variant={props.variant || "contained"} color={props.color || "primary"} type={props.type || "submit"} onClick={() => click(props.action || "redirect", props.param || null, props.attribute || "personalizados")}>
            {props.children}
        </Button >
    )
}

export default ButtonWhatsApp